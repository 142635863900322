<template>
  <div class="ui">
    <div class="ui-left-corner">
      <ul class="ui-left-corner__status">
        <li class="ui-left-corner__status-item">
          <component :is="icons['iconMouse']" 
            class="ui-left-corner__status-icon"
            :class="{active: status.middleMousePressed}" />
        </li>
      </ul>
      <ul class="ui-left-corner__stats">
        <li class="ui-left-corner__stats-item">
          <span>Scale</span>
          <span>{{Math.round(options.scale * 10)/10}}</span>
        </li>
        <li class="ui-left-corner__stats-item">
          <span>x</span>
          <span>{{ Math.round(position[0]) }}</span>
        </li>
        <li class="ui-left-corner__stats-item">
          <span>y</span>
          <span>{{ Math.round(-position[1]) }}</span>
        </li>
        <li class="ui-left-corner__stats-item">
          <span>Count of nodes</span>
          <span>{{nodesCount}}</span>
        </li>
      </ul>
      <graph-editor-options-ui
          :options="options"
          :snapshots="snapshots"
          :selectedNodes="selectedNodes"
          :copyData="copyData"
          :moveConnection="editorMode"
          @centerPosition="$emit('centerPosition')"
          @changeGridBinding="$emit('changeGridBinding', $event)"
          @changeScale="$emit('changeScale', $event)"
          @info="$emit('info')"
          @revert="$emit('revert')"
          @copy="$emit('copy')"
          @cut="$emit('cut')"
          @paste="$emit('paste')"
          @setEditorMode="$emit('setEditorMode')"
      />
    </div>
    <graph-editor-ui-select
        v-if="selectStart && selectEnd && editorMode"
        :selectStart="selectStart"
        :selectEnd="selectEnd"
    />
    <slot></slot>
  </div>
</template>

<script>
import iconsVue from "../../mixins/iconsVue.js";

import GraphEditorOptionsUi from "./GraphEditorOptionsUi.vue";
import GraphEditorUiSelect from "./GraphEditorUiSelect.vue";

export default {
  components: {
    GraphEditorOptionsUi,
    GraphEditorUiSelect
  },
  mixins: [iconsVue],
  props: {
    options: {
      required: true,
      type: Object
    },
    position: {
      required: true,
      type: Array,
    },
    nodes: {
      required: true,
      type: Object,
    },
    status: {
      required: true,
      type: Object,
    },
    selectStart: {
      required: false,
      type: Array,
    },
    selectEnd: {
      required: false,
      type: Array,
    },
    snapshots: {
      required: false,
      type: Array
    },
    selectedNodes: {
      required: false,
      type: Object
    },
    copyData: {
      required: false,
      type: Object
    },
    editorMode: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    nodesCount() {
      return Object.keys(this.nodes).length;
    },
  },
}

</script>

<style lang="scss">

.ui {}

.ui-left-corner {
  position: absolute;
  left: 75px;
  bottom: 50px;
  z-index: 10;
  pointer-events: none;
}

.ui-left-corner__stats {
  padding: 0 !important;
  margin-bottom: 10px;
  list-style: none;
}

.ui-left-corner__stats-item {
  padding: 0;
  font-size: 12px;
  margin-bottom: 3px;

  &:last-child {
    margin-bottom: 0;
  }

  & span {
    margin-right: 10px;

    &:first-child {
      font-weight: bold;

      &::after {
        content: ":";
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.ui-left-corner__status {
  padding: 0 !important;
  margin-bottom: 10px;
  list-style: none;
}

.ui-left-corner__status-item {}

.ui-left-corner__status-icon {
  max-width: 35px;
  max-height: 35px;
  fill: #7d838f;

  &.active {
    fill: #ff6d5a;
  }
}

</style>