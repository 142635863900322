import {GROUP_TYPE} from "../nodes/group/index.js";

import nodeCanBeDeleted from "../helpers/workflow/nodes/nodeCanBeDeleted.js";
import getGroupNodeOutputs from "../helpers/workflow/nodes/group/getGroupNodeOutputs.js";

export default {
    props: {
        node: {
            type: Object,
            required: true
        },
        nodeTypeInfo: {
            type: Object,
            required: false
        },
        nodes: {
            type: Object,
            required: true
        },
        connections: {
            type: Object,
            required: true
        },
        availableNodes: {
            type: Object,
            required: true
        },
        scale: {
            type: Number,
            required: true
        },
        minScale: {
            type: Number,
            required: true
        },
        cellSize: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            changingDisplayName: false,
            isOk: false,
        };
    },
    computed: {
        root() {
            return this.$parent.$parent;
        },
        type() {
            const overwritten = this.node['overwrittenNodeType'] || {};
            return {
                ...this.nodeTypeInfo,
                ...overwritten
            };
        },
        subgroup() {
            return this.node["grouped"] && this.node.type === GROUP_TYPE ?  this.node.grouped : undefined;
        },
        subgroupContext() {
          if(!this.subgroup) return undefined;

          return { grouped: this.subgroup, parentNodeId: this.node.id, name: this.node.displayName };
        },
        nodeWidth() {
            const subgroupFactor = this.subgroup ? 1 : 0;
            return Math.max(this.cellSize * 2, this.cellSize * 6 * subgroupFactor);
        },
        nodeHeight() {
            const subgroupFactor = this.subgroup ? 1 : 0;

            const outputsLength = this.type.outputs.length;
            const heightScale = (outputsLength > 2 ? .9 * outputsLength : 2);

            const normalHeight = heightScale * this.cellSize;

            return normalHeight + subgroupFactor * this.cellSize * 3;
        },
        canBeDeleted() {
            return nodeCanBeDeleted(this.node, this.availableNodes);
        },
        outputs() {
            // group like node have a connect for next node
            if(this.node.type === GROUP_TYPE && this.node.grouped && !this.node.overwrittenNodeType) {
                return ['main']
            }

            return this.type.outputs;
        },
        outputNames() {
            // group like node have a connect for next node
            // if(this.node.type === GROUP_TYPE) {
            //     return getGroupNodeOutputs(this.node).outputNames;
            // }

            return this.type.outputNames ?? [];
        }
    },
    methods: {
        deleteNode() {
            this.$emit('deleteNode', this.node.id);
        },

        startRename() {
            this.changingDisplayName = true;
            this.$nextTick(() => {
                this.$refs.nameInput.focus();
            });
        },

        endRename() {
            this.changingDisplayName = false;
        },

        /**
         * @param {KeyboardEvent} event
         */
        enterPress(event) {
            if(!event) return;

            if(event.code === 'Enter' && this.changingDisplayName) this.changingDisplayName = false;
        },

        /**
         * @param {MouseEvent} event
         */
        startOutputDragging(event) {
            if(!event) return;

            this.$emit('startOutputDragging', event);
        },

        outputIsPlus(outputName) {
            if(!outputName) {
                console.error('Cant check outputIsPlus, wrong params');
                return;
            }

            return !(this.root.connections[this.node.id] && this.root.connections[this.node.id][outputName]);
        },
    },
    beforeMount() {
        if(!this.nodeTypeInfo && !this.node['overwrittenNodeType']) {
            console.error(`Cant mount node, node available info doesnt exists \n NodeID:  ${this.node.id} \n NodeType: ${this.node.type}`);
            return;
        }

        this.isOk = true;
    },
}