/**
 * Возвращает объект отфильтрованных узлов.
 * @param {string} searchName
 * @param {Object} availableNodes
 * @return {Object}
 */
export default (searchName, availableNodes) => {
    if(!searchName || typeof searchName !== 'string' || !availableNodes || typeof availableNodes !== 'object') {
        console.error('Cant filter available nodes, wrong params');
        return;
    }

    const transformedSearchName = searchName.toLowerCase().replaceAll(' ', '');

    return Object.entries(availableNodes).reduce((acc, availableNodePair) => {
        const nodeType = availableNodePair[0];
        const node = availableNodePair[1];

        const transformedNodeName = node.name.toLowerCase().replaceAll(' ', '');

        if(transformedNodeName.indexOf(transformedSearchName) !== -1) {
            acc[nodeType] = node;
        }

        return acc;
    }, {});
}