<template>
  <svg
      class="connection"
      :width="width"
      :height="height"
      :style="{
        left: `${minPoint[0]}px`,
        top: `${minPoint[1]}px`
      }"
  >
    <marker
        :id="id"
        v-if="withMarker"
        markerWidth="7"
        markerHeight="7"
        refX="7"
        refY="3.5"
        orient="auto"
        :style="{
          fill: color
        }"
    >
      <polygon
          points="0 0, 7 3.5, 0 7"
      />
    </marker>
    <path
        :marker-end="`url(#${id})`"
        :d="currentLine"
        :style="{
          stroke: color
        }"
        @mouseenter="$emit('mouseenter')"
        @mouseleave="$emit('mouseleave')"
        @click="$emit('click')"
    />
    <path
        :d="currentLine"
        :style="{
          stroke: 'transparent',
          strokeWidth: '20px'
        }"
        @mouseenter="$emit('mouseenter')"
        @mouseleave="$emit('mouseleave')"
        @click="$emit('click')"
    />
  </svg>
</template>

<script>
import {ARROW_CURVE_LINE_DISTANCE} from "../config/constants.js";

import calculateDistance from "../helpers/math/calculateDistance.js";

export default {
  props: {
    from: {
      type: Array,
      required: true
    },
    to: {
      type: Array,
      required: true
    },
    withMarker: {
      type: Boolean,
      required: false,
      default: true,
    },
    color: {
      type: String,
      required: false
    },
    positionConnect: {
      type: Object
    }
  },
  data() {
    return {
      ARROW_CURVE_LINE_DISTANCE,
      gap: 150,
      id: (new Date()).getTime().toString() + Math.random().toString(),
      typeLine: ''
    };
  },
  watch: {
    typeLine(typeLineConnect){
      this.$emit('typeLine', typeLineConnect)
    }
  },
  computed: {
    positionConnectX(){
      if(this.positionConnect?.x) return this.positionConnect.x
      return 0
    },
    positionConnectY(){
      if(this.positionConnect?.y) return this.positionConnect.y
      return 0
    },
    leftPostionLine(){
      if(this.positionConnectX && this.positionConnect.x < 0) return this.positionConnect.x;
      return 0;
    },
    topPostionLine(){
      if(this.positionConnectY && this.positionConnect.y < 0) return this.positionConnect.y;
      return 0;
    },
    extraWidth(){
      if(this.positionConnectX && this.positionConnect.x > 0) return this.positionConnect.x;
      return 0;
    },
    extraHeight(){
      if(this.positionConnectY && this.positionConnect.y > 0) return this.positionConnect.y;
      return 0;
    },
    minPoint() {
      return [
        Math.min(this.from[0], this.to[0]) - this.halfGap + this.leftPostionLine,
        Math.min(this.from[1], this.to[1]) - this.halfGap + this.topPostionLine
      ];
    },
    maxPoint() {
      return [
        Math.max(this.from[0], this.to[0]) + this.halfGap,
        Math.max(this.from[1], this.to[1]) + this.halfGap
      ];
    },
    width() {
      return this.maxPoint[0] - this.minPoint[0] + this.halfGap + this.extraWidth;
    },
    height() {
      return this.maxPoint[1] - this.minPoint[1] + this.extraHeight;
    },
    halfGap() {
      return this.gap/2;
    },
    correctedStart() {
      return [
        this.from[0] - this.minPoint[0],
        this.from[1] - this.minPoint[1],
      ];
    },
    correctedEnd() {
      return [
        this.to[0] - this.minPoint[0],
        this.to[1] - this.minPoint[1],
      ];
    },
    middlePosition() {
      return [
        (this.correctedStart[0] + this.correctedEnd[0]) / 2,
        (this.correctedStart[1] + this.correctedEnd[1]) / 2,
      ];
    },
    distanceBetweenPoints() {
      return calculateDistance(this.minPoint, this.maxPoint);
    },
    xDirection() {
      return Math.abs(this.xDistance) / - this.xDistance;
    },
    xDistance() {
      return this.correctedStart[0] - this.correctedEnd[0];
    },
    yDirection() {
      return Math.abs(this.yDistance) / - this.yDistance;
    },
    yDistance() {
      return this.correctedStart[1] - this.correctedEnd[1];
    },
    straightLine() {
      return `
      M${this.correctedStart[0]} ${this.correctedStart[1]}
      L${this.positionConnectX + (this.correctedStart[0] + this.correctedEnd[0])/2} ${this.correctedStart[1]}
      L${this.positionConnectX + (this.correctedStart[0] + this.correctedEnd[0])/2} ${this.correctedEnd[1]}
      L${this.correctedEnd[0]} ${this.correctedEnd[1]}
      `;
    },
    // curveLine() {
    //   return `
    //   M${this.correctedStart[0]} ${this.correctedStart[1]}
    //   L${this.correctedStart[0] + 10} ${this.correctedStart[1]}
    //   C${this.correctedStart[0] + (100 * this.xDirection)} ${this.correctedStart[1]}
    //   ${this.correctedEnd[0] - (120 * this.xDirection)} ${this.correctedEnd[1]}
    //   ${this.correctedEnd[0] - (20 * this.xDirection)} ${this.correctedEnd[1]}
    //   L${this.correctedEnd[0]} ${this.correctedEnd[1]}
    //   `;
    // },
    backLine() {
      return `
      M${this.correctedStart[0]} ${this.correctedStart[1]}
      L${this.positionConnectX + this.correctedStart[0] + 10} ${this.correctedStart[1]}
      L${this.positionConnectX + this.correctedStart[0] + 10} ${this.positionConnectY + this.middlePosition[1]}
      L${this.positionConnectX + this.correctedEnd[0] + (50 * this.xDirection)} ${this.positionConnectY +  this.middlePosition[1]}
      L${this.positionConnectX + this.correctedEnd[0] + (50 * this.xDirection)} ${this.correctedEnd[1]}
      L${this.correctedEnd[0]} ${this.correctedEnd[1]}
      `;
    },
    /* eslint-disable */
    currentLine() {
      if(this.from[0] > this.to[0]) {
        this.typeLine = 'backLine';
        return this.backLine;
      }
      
      // hide curveLine
      // return this.distanceBetweenPoints > ARROW_CURVE_LINE_DISTANCE ? this.curveLine : this.straightLine;
      this.typeLine = 'straightLine';
      return this.straightLine;
    },
  }
}

</script>

<style scoped lang="scss">

.connection {
  position: absolute;
  fill: none;
  pointer-events: none;

  & path {
    stroke-width: 2px;
    stroke: #7d838f;
    pointer-events: visiblePainted;
  }

  & marker {
    fill: #7d838f;
  }
}

</style>